<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >{{ $t('partners::partners.columns.code').ucFirst() }} *</label
                    >

                    <input
                        id="code"
                        v-model="model.code"
                        :class="{'border-danger': states.code === false}"
                        :placeholder="$t('partners::partners.placeholders.code').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div class="col-lg-8">

                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('partners::partners.columns.name').ucFirst() }} *</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('partners::partners.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.registration_number === false}"
                        class="form-label"
                        for="registration-number"
                    >{{ $t('partners::partners.columns.registration_number').ucFirst() }}</label
                    >
                    <input
                        id="registration-number"
                        v-model="model.registration_number"
                        :class="{'border-danger': states.registration_number === false}"
                        :placeholder="$t('partners::partners.placeholders.registration_number').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.registration_number === false}" class="invalid-feedback">
                        {{ errors.registration_number }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.vat_number === false}"
                        class="form-label"
                        for="vat_number"
                    >{{ $t('partners::partners.columns.vat_number').ucFirst() }}</label
                    >
                    <input
                        id="vat_number"
                        v-model="model.vat_number"
                        v-maska="'########-#-##'"
                        :class="{'border-danger': states.vat_number === false}"
                        :placeholder="$t('partners::partners.placeholders.vat_number').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.vat_number === false}" class="invalid-feedback">{{
                            errors.vat_number
                        }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.international_vat_number === false}"
                        class="form-label"
                        for="international_vat_number"
                    >{{ $t('partners::partners.columns.international_vat_number').ucFirst() }}</label
                    >
                    <input
                        id="international_vat_number"
                        v-model="model.international_vat_number"
                        :class="{'border-danger': states.international_vat_number === false}"
                        :placeholder="$t('partners::partners.placeholders.international_vat_number').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.international_vat_number === false}" class="invalid-feedback">
                        {{ errors.international_vat_number }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.company_size_id === false}"
                        class="form-label"
                        for="company_size_id"
                    >{{ $t('partners::partners.columns.company_size_id').ucFirst() }}</label>
                    <select
                        id="company_size_id"
                        v-model="model.company_size_id"
                        :class="{'border-danger': states.company_size_id === false}"
                        class="form-select"
                        name="company_size_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('partners::partners.placeholders.company_size_id').ucFirst() }}
                        </option>
                        <option v-for="(companySize, key) in companySizes" :key="key" :value="companySize.id">{{
                                companySize.translation_key ? $t(companySize.translation_key).ucFirst() : companySize.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.company_size_id === false}" class="invalid-feedback">
                        {{ errors.company_size_id }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.industry_id === false}"
                        class="form-label"
                        for="industry_id"
                    >{{ $t('partners::partners.columns.industry_id').ucFirst() }}</label>
                    <select
                        id="industry_id"
                        v-model="model.industry_id"
                        :class="{'border-danger': states.industry_id === false}"
                        class="form-select"
                        name="industry_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('partners::partners.placeholders.industry_id').ucFirst() }}
                        </option>
                        <option v-for="(industry, key) in industries" :key="key" :value="industry.id">
                            {{ industry.translation_key ? $t(industry.translation_key).ucFirst() : industry.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.industry_id === false}" class="invalid-feedback">
                        {{ errors.industry_id }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.lead_source_id === false}"
                        class="form-label"
                        for="lead_source_id"
                    >{{ $t('partners::partners.columns.lead_source_id').ucFirst() }}</label>
                    <select
                        id="lead_source_id"
                        v-model="model.lead_source_id"
                        :class="{'border-danger': states.lead_source_id === false}"
                        class="form-select"
                        name="lead_source_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('partners::partners.placeholders.lead_source_id').ucFirst() }}
                        </option>
                        <option v-for="(leadSource, key) in leadSources" :key="key" :value="leadSource.id">{{
                                leadSource.translation_key ? $t(leadSource.translation_key).ucFirst() : leadSource.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.lead_source_id === false}" class="invalid-feedback">
                        {{ errors.lead_source_id }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.email === false}"
                        class="-form-label"
                        for="email"
                    >{{ $t('partners::partners.columns.email').ucFirst() }}</label
                    >
                    <input
                        id="email"
                        v-model="model.email"
                        :class="{'border-danger': states.email === false}"
                        :placeholder="$t('partners::partners.placeholders.email').ucFirst()"
                        class="form-control"
                        type="email"
                    />
                    <div :class="{'d-block': states.email === false}" class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.phone === false}"
                        class="form-label"
                        for="phone"
                    >{{ $t('partners::partners.columns.phone').ucFirst() }}</label
                    >
                    <vue-tel-input
                        id="phone"
                        v-model="phone"
                        :inputOptions="{
                            id: 'phone',
                            placeholder: $t('partners::partners.placeholders.phone').ucFirst(),
                            styleClasses: {'border-danger': states.phone === false}
                        }"
                        :invalidMsg="errors.phone"
                        defaultCountry="hu"
                        validCharactersOnly
                        mode="international"
                    ></vue-tel-input>
                    <div :class="{'d-block': states.phone === false}" class="invalid-feedback">{{ errors.phone }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.users === false}"
                    class="form-label"
                    for="users"
                >{{ $t('partners::partners.relationships.users').ucFirst() }}</label
                >
                <div class="col-lg-12">
                    <select
                        id="users"
                        class="form-select"
                        v-model="user"
                        :class="{'border-danger': states.users === false}"
                    >
                        <option disabled :value="user === undefined ? undefined : null">{{ $t('partners::partners.placeholders.users').ucFirst() }}</option>
                        <option v-for="(user, key) in filteredUsers" :key="key" :value="user.id">{{ user.name }}</option>
                    </select>
                    <input
                        v-if="false"
                        id="users"
                        v-model="user"
                        :class="{'border-danger': states.users === false}"
                        :placeholder="$t('partners::partners.placeholders.users').ucFirst()"
                        class="form-control"
                        list="user-list"
                        type="text"
                    />
                    <div :class="{'d-block': states.users === false}" class="invalid-feedback">{{ errors.users }}</div>
                    <datalist v-if="false" id="user-list">
                        <option v-for="(user, key) in users" :key="key" :value="user.id">{{ user.name }}</option>
                    </datalist>
                    <div class="mt-1">
                        <span v-for="(id) in partnerUsers" :key="id"
                              :class="getRandomColor()"
                              class="pt-1 badge rounded-pill d-inline-flex align-items-center me-2">
                        <span class="d-flex align-items-center">
                            <input :checked="Number(main_user) === id" :value="id" class="me-1" name="is_main"
                                   type="checkbox" @change="setMainUser"/>
                            {{ getUserName(id) }}
                        </span>
                        <i class="ms-2 bx bx-x cursor-pointer" @click="remove(id)"></i>
                    </span>
                    </div>
                </div>
            </div>
            <template v-for="(field, index) in fields" :key="index">
                <additional-versionable-field
                    v-if="field.versionable"
                    v-model="model[field.transformed_name]"
                    :field="field"
                    :state="states[field.transformed_name]"
                    :error="errors[field.transformed_name]"
                    @update:modelValue="newValue => model[field.transformed_name] = newValue"
                ></additional-versionable-field>

                <div v-else class="row mb-4">
                    <additional-field
                        v-model="model[field.transformed_name]"
                        :field="field"
                        :state="states[field.transformed_name]"
                        :error="errors[field.transformed_name]"
                        @update:modelValue="newValue => model[field.transformed_name] = newValue"
                    ></additional-field>
                </div>
            </template>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import AdditionalField from "../elements/additional-field.vue";
import AdditionalVersionableField from "../elements/additional-versionable-field.vue";

export default {
    name: "PartnerForm",
    components: {AdditionalVersionableField, AdditionalField},

    emits: ['saved'],

    props: {
        partner: {
            type: Object,
            required: true
        },

        useAuthenticatedAsDefault: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'partner/loading',
            'states': 'partner/states',
            'errors': 'partner/errors',
            'authenticated': 'user/authenticated',
            'users': 'user/all',
            fields: 'field/list',
            companySizes: 'companySize/all',
            industries: 'industry/all',
            leadSources: 'leadSource/all',
            fieldVersions: 'fieldVersion/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        partnerUsers: function () {
            const arr = this.model.users

            if (!Array.isArray(arr)) {
                return arr
            }

            return arr.map(item => {
                if (typeof item === 'object') {
                    if (item.pivot.is_main) {
                        this.main_user = item.id
                    }
                    return item.id;
                }
                return item
            });
        },

        filteredUsers: function () {
            if (!this.users || !this.model) {
                return []
            }

            if (!this.model.users) {
                return this.users
            }

            return this.users.filter(user => !this.model.users.includes(user.id));
        },
    },

    data: function () {
        return {
            model: {},
            main_user: null,
            user: null,
            phone: (this.partner && this.partner.phone) || '',
            dynamicSkeleton: {}
        }
    },

    methods: {
        setMainUser: function (event) {
            this.main_user = event.target.value;
        },

        getUserName: function (id) {
            const user = this.users.find(user => user.id === id)

            return user ? user.name : id
        },

        getRandomColor: function () {
            const classes = ['bg-primary', 'bg-info', 'bg-success', 'bg-warning', 'bg-danger'];

            return classes[Math.floor(Math.random() * classes.length)]
        },

        remove: function (id) {
            this.model.users.splice(this.model.users.findIndex(user => user === id), 1)
        },

        setDefaultUser: function () {
            if (!this.useAuthenticatedAsDefault) return;

            if (!Array.isArray(this.model.users)) {
                this.model.users = []
            }

            if (
                ! this.model.users.includes(this.authenticated.id)
                && !this.model.users.filter(user => {
                    if (typeof user === 'object') {
                        return user.id === this.authenticated.id
                    }
                    return false;
                }).length
            ) {
                this.model.users.push(this.authenticated.id);
                this.main_user = this.authenticated.id;
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({with: ['fields', 'fieldValues', 'fieldValues.fieldVersion']}, this.model, {main_user: this.main_user, users: this.partnerUsers})

            return this.$store.dispatch(`partner/${method}`, data).then(saved => {
                if (!['string', 'number'].includes(typeof saved.phone)) {
                    saved.phone = '';
                }
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.'+'/partners/:id(\\d+)/base-information/:type?', {id: this.model.id, type: this.$route.params.type}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, this.dynamicSkeleton, JSON.parse(JSON.stringify(model)))
        },
    },

    watch: {
        partner: function (value) {
            this.setModel(value)
            this.phone = (this.partner && this.partner.phone) || ''
        },

        user: function (id) {
            if (!id) {
                return
            }

            let n = Number(id)

            if (!this.model.users) {
                this.model.users = [];
            }

            if (!this.model.users.includes(n)) {
                this.model.users.push(n);
                if (!this.main_user) {
                    this.main_user = id;
                }
            }

            this.user = null
        },

        phone: function (value) {
            this.model.phone = value
            //.replace(/\s|\t|\n|\+/g, '')
        },

        fields: function (fields) {
            const skeleton = {}

            fields.forEach(field => {
                if (field.versionable) {
                    skeleton[field.transformed_name] = [{
                        version: {
                            name: null,
                            date: null
                        },
                        item: {}
                    }];
                }
            })

            this.dynamicSkeleton = skeleton

            this.setModel(this.model)
        },
    },

    created() {
        this.setModel(this.partner)
        this.setDefaultUser()
        this.$store.dispatch('field/list', {owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType'})
    }
}
</script>

<style scoped>

</style>
